body {
  background-color: var(--neutralDark);
  color: var(--textPrimary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}

button {
  border: none;
  background: transparent;
  color: inherit;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.StripeElement {
  outline: 1px solid var(--textSecondary);
  padding: 8px 8px;
  background: transparent;
  caret-color: var(--textPrimary);
  color: var(--textPrimary);
}

.StripeElement--focus {
  outline: 1px solid white;
}
