body {
  --neutralPrimary: #282828;
  --neutralDark: #1f1f1f;
  --neutralDarker: #181818;
  --neutralLight: #353535;
  --neutralLighter: #4b4b4b;
  --textPrimary: #e9e9e9;
  --textSecondary: rgb(189, 189, 189);
  --textTertiary: #f1f1f1;
  --themePrimary: #de5d5f;
  --themeSecondary: #c35254;
  --themeTranslucent: rgba(241, 126, 126, 0.33);
  --themeTertiary: #ffc4c4;
  --errorMessageColor: #ef516e;
}
